import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Box } from "@mui/system";
import { loader } from "graphql.macro";
import { useNavigate, useLocation } from "react-router";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import useFetch from "use-http";
import useAuth from "../../hooks/useAuth";
import useConsole from "../../hooks/useConsole";
import { useTheme } from "@mui/material/styles";
import { WS_STATUS } from "../../constants/websocket";
import Support from "../Roles/Support";

const BOXES = loader("../../graphql/boxes.graphql");
const UPDATE = loader("../../graphql/updateOneBox.graphql");

export function BoxDetails({ user }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const authHook = useAuth();
  const consoleHook = useConsole(authHook.token, true);
  const { boxIds } = state;
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { loading, error, data } = useQuery(BOXES, {
    pollInterval: window.__RUNTIME_CONFIG__.REACT_APP_POLL_INTERVAL,
    variables: {
      filter: {
        mac: {
          in: boxIds,
        },
      },
      paging: { limit: 10000, offset: 0 },
    },
  });

  const boxes = data?.boxes?.nodes;

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ maxWidth: "90vw" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {boxes.map((box, index) => (
            <Tab
              key={box.mac}
              label={box.mac}
              id={box.mac}
              style={{
                color:
                  box?.supervision_wsStatus === WS_STATUS.CONNECTED
                    ? theme.palette.success.main
                    : theme.palette.warning.main,
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box m={2}>
        {boxes.map((box, index) => (
          <TabPanel
            key={box.mac}
            value={value}
            index={index}
            box={box}
            consoleHook={consoleHook}
            user={user}
          />
        ))}
      </Box>
      <Button variant="outlined" onClick={() => navigate(-1)}>
        Retour
      </Button>
    </Box>
  );
}

const SCRIPT = loader("../../graphql/script.graphql");

function TabPanel({ value, index, box, consoleHook, user }) {
  const { enqueueSnackbar } = useSnackbar();
  const [scenario, setScenario] = React.useState(box.configuration_scenarioId);
  const [newScenario, setNewScenario] = React.useState(
    box.configuration_scenarioId,
  );
  const host =
    process.env?.REACT_APP_DEV === "true"
      ? "/mc2int.uniboservices.com"
      : box.configuration_supervision;
  const url = `https://${host}/getscenesfrometab.php?code=${box.configuration_hotel}&format=json`;
  const { loading, error, data = [] } = useFetch(url, []);
  const urlSetContent = `https://${host}`;
  const { get: getSetContent, error: errorSetContent } = useFetch(
    urlSetContent,
    { cachePolicy: "no-cache" },
  );

  const configuration = Object.keys(box)
    .filter((key) => key.includes("configuration_"))
    .reduce((cur, key) => {
      const newkey = key.replace("configuration_", "");
      return Object.assign(cur, { [newkey]: box[key] });
    }, {});
  const software = Object.keys(box)
    .filter((key) => key.includes("software_"))
    .reduce((cur, key) => {
      const newkey = key.replace("software_", "");
      return Object.assign(cur, { [newkey]: box[key] });
    }, {});
  const hardware = Object.keys(box)
    .filter((key) => key.includes("hardware_"))
    .reduce((cur, key) => {
      const newkey = key.replace("hardware_", "");
      return Object.assign(cur, { [newkey]: box[key] });
    }, {});
  const network = Object.keys(box)
    .filter((key) => key.includes("network_"))
    .reduce((cur, key) => {
      const newkey = key.replace("network_", "");
      return Object.assign(cur, { [newkey]: box[key] });
    }, {});

  const [customDetails, setCustomdetails] = React.useState(
    box?.custom_details || "",
  );

  const [updateOneBox] = useMutation(UPDATE, {
    onCompleted: () =>
      enqueueSnackbar(`Custom details updated successfully: ${box.mac}`),
  });

  const { refetch: refetchScript } = useQuery(SCRIPT, {
    fetchPolicy: "network-only",
    variables: {
      id: 5,
    },
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  if (error) {
    console.log(error);
  }
  if (errorSetContent) {
    console.log(errorSetContent);
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSaveCustomDetails = () => {
    updateOneBox({
      variables: {
        input: {
          id: box.mac,
          update: {
            custom_details: customDetails,
          },
        },
      },
    });
  };

  const onChangeScenario = async (event) => {
    setNewScenario(event.target.value);
    const disableConfirmationDialog = window.localStorage.getItem(
      "disableConfirmationDialog",
    );
    if (disableConfirmationDialog === "true") {
      boxsetcontent();
    } else {
      setOpen(true);
    }
  };

  const boxsetcontent = async () => {
    setScenario(newScenario);
    try {
      const res = await refetchScript();
      // check if script of ID 5 is Patch Content
      if (res?.data?.script?.name === "Patch Content") {
        const url = `/boxsetcontent.php?boxes=${box.mac}&box_scenario=${newScenario}`;
        console.log("boxsetcontent");
        await getSetContent(url);
        console.log("execScript", box.mac, 5);
        consoleHook.mainExecScript(box.mac, 5); // patch content
      } else {
        console.error("Script of ID 5 is not named 'Patch Content");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const scenarioName = data.find(
    (data) => data.id === scenario?.toString(),
  )?.text;
  const newScenarioName = data.find((data) => data.id === newScenario)?.text;
  console.log(scenario, scenarioName, data);

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box>
          <Box m={2}>
            <Box m={1}>
              <Support user={user}>
                <Select value={scenario} onChange={onChangeScenario}>
                  {data.map((sc) => (
                    <MenuItem value={sc.id}>{sc.text}</MenuItem>
                  ))}
                </Select>
                <ScenarioDialog
                  open={open}
                  onClose={handleClose}
                  callback={boxsetcontent}
                  scenarioName={scenarioName}
                  newScenarioName={newScenarioName}
                />
              </Support>
            </Box>

            <Box ml={1}>
              <TextField
                multiline
                fullWidth
                rows={4}
                value={customDetails}
                onChange={(e) => setCustomdetails(e.target.value)}
                label="Custom field"
              ></TextField>
            </Box>
            <Box mx={1}>
              <Button variant="contained" onClick={handleSaveCustomDetails}>
                Save
              </Button>
            </Box>
          </Box>
          <Stack direction="row" spacing={2}>
            <ObjTable title="Configuration" obj={configuration}></ObjTable>
            <ObjTable title="Software" obj={software}></ObjTable>
            <ObjTable title="Network" obj={network}></ObjTable>
            <ObjTable title="Hardware" obj={hardware}></ObjTable>
          </Stack>
        </Box>
      )}
    </div>
  );
}

function ObjTable({ title, obj }) {
  const [order, setOrder] = React.useState("asc");

  const sort = () => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
  };

  return (
    <TableContainer component={Paper} sx={{ overflow: "visible" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <TableSortLabel
                active={order ? true : false}
                direction={order}
                onClick={sort}
              >
                {title}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ObjRow obj={obj} order={order}></ObjRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ObjRow({ obj, order }) {
  let keys = Object.keys(obj);
  if (order) {
    keys =
      order === "asc"
        ? keys.sort()
        : keys.sort((a, b) => (a === b ? 0 : a > b ? -1 : 1));
  }
  return keys.map((key) => (
    <TableRow key={key}>
      <TableCell sx={{ fontWeight: "bold" }}>{key}</TableCell>
      <TableCell>
        {obj[key]}
        <CopyToClipboard text={obj[key]}>
          <IconButton size="small" sx={{ float: "right" }}>
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </CopyToClipboard>
      </TableCell>
    </TableRow>
  ));
}

function ScenarioDialog(props) {
  const { onClose, open, callback, scenarioName, newScenarioName } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    callback();
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Scenario confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>From : {scenarioName}</div>
          <div>To : {newScenarioName}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
